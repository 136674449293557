
import { ValidationProvider } from "vee-validate";
import { Component, Vue, Prop, Watch, Emit } from "vue-property-decorator";
import DateRangePicker from "vue-mj-daterangepicker-custom-presets";
import "vue-mj-daterangepicker-custom-presets/dist/vue-mj-daterangepicker.css";
import { startOfISOWeek, startOfMonth, startOfYear, subWeeks, subMonths, subYears, endOfISOWeek, endOfMonth, endOfYear } from "date-fns";

Vue.use(DateRangePicker);

@Component({
    components: {
        ValidationProvider,
    },
})
export default class FormDateRangePicker extends Vue {
    @Prop() value?: { from: string; to: string };

    @Prop({ default: () => [] }) rangeSubjects?: Array<{ label: string; value: string; default?: boolean }>;

    localValue: { from: string; to: string; subject?: string | null } = { from: "", to: "", subject: null };

    show: boolean = false;

    selectedSubject: string | null = null;

    presets = [
        { name: "Deze week", from: startOfISOWeek(new Date().toISOString()), to: new Date().toISOString() },
        { name: "Vorige week", from: startOfISOWeek(subWeeks(new Date().toISOString(), 1)), to: endOfISOWeek(subWeeks(new Date().toISOString(), 1)) },
        { name: "Deze maand", from: startOfMonth(new Date().toISOString()), to: new Date().toISOString() },
        { name: "Vorige maand", from: startOfMonth(subMonths(new Date().toISOString(), 1)), to: endOfMonth(subMonths(new Date().toISOString(), 1)) },
        { name: "Dit jaar", from: startOfYear(new Date().toISOString()), to: new Date().toISOString() },
        { name: "Vorig jaar", from: startOfYear(subYears(new Date().toISOString(), 1)), to: endOfYear(subYears(new Date().toISOString(), 1)) },
        "custom",
    ];

    get clickOutsideConfig() {
        return {
            handler: this.closeDatepicker,
            isActive: this.show,
            capture: true,
        };
    }

    closeDatepicker() {
        this.show = false;
    }

    handleUpdate(values: { from: string; to: string; panel: "range" }) {
        this.closeDatepicker();
        this.localValue.from = values.from;
        this.localValue.to = values.to;
        if (this.selectedSubject) {
            this.localValue.subject = this.selectedSubject;
        }
        this.handleInput();
    }

    @Emit("input")
    handleInput() {
        return this.localValue;
    }

    @Watch("value", { immediate: true, deep: true })
    private valueChanged(newValue: { from: string; to: string; subject?: string }) {
        this.localValue = newValue;

        this.selectedSubject = newValue.subject ? newValue.subject : this.rangeSubjects?.find((subject) => subject.default)?.value ?? null;
    }
}
